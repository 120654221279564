<template>
  <Modal v-model="open" :title="title">
    <Form ref="formItem" :model="formItem" :label-width="80" :rules="ruleValidate">
      <FormItem label="名称" prop="name">
        <Input v-model="formItem.name" placeholder="请输入名称" clearable />
      </FormItem>
    </Form>
    <div slot="footer" style="text-align: center">
      <Button @click="cancel('formItem')">关闭</Button>
      <Button type="primary" :loading="loading" @click="ok('formItem')">
        提交
      </Button>
    </div>
  </Modal>
</template>
<script>
import { goodTypeUpdate, goodTypeAdd } from '@/api/goodtype'
export default {
  name: 'Goods',
  data () {
    return {
      formItem: {
        name: '',
      },
      ruleValidate: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      open: false,
      title: '类别修改',
      loading: false,
      imgUrl: '',
    }
  },
  methods: {
    init (params) {
      if (params) {
        this.title = '类别修改'
        this.formItem = JSON.parse(JSON.stringify(params))
        this.imgUrl = process.env.VUE_APP_BASEURL + params.img
      } else {
        this.title = '类别添加'
      }
      this.open = true
    },
    ok (name) {
      this.$refs[name].validate((valid) => {
        if (!valid) return
        if (this.formItem._id) {
          goodTypeUpdate(this.formItem)
            .then((res) => {
              if (res.data.code === 200) {
                this.$Message.success('类别修改成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.$Message.error(err)
            })
        } else {
          goodTypeAdd(this.formItem)
            .then((res) => {
              if (res.data.code === 200) {
                this.$Message.success('类别添加成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.$Message.error(err)
            })
        }
      })
    },
    cancel (name) {
      this.formItem = {
        name: '',
        price: '',
        desc: '',
        typeid: '',
      }
      this.$refs[name].resetFields()
      this.open = false
    },
  },
  mounted () { },
}
</script>
<style lang="less" scoped>
.uploadImgUrl {
  width: 50px;
  height: 50px;
}
</style>
